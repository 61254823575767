import React from "react";
import Registers from "../../components/Register/Registers";

const Register = () => {
  return (
    <>
      <Registers />
    </>
  );
};

export default Register;
