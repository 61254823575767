export const GET_ALL_CONTACTS = "getAllContactsfun";
export const GET_ALL_CONTACTS_LOADING = "getAllContactsfunloading";
export const GET_SINGLE_CONTACTS = "getSingleContactsfun";
export const GET_SINGLE_CONTACTS_LOADING = "getSingleContactsfunloading";
export const GET_POST_CONTACTS = "postContactsfun";
export const GET_POST_CONTACTS_LOADING = "postContactsfunloading";
export const GET_PUT_CONTACTS = "putContactsfun";
export const GET_PUT_CONTACTS_LOADING = "putContactsfunloading";
export const GET_DELETE_CONTACTS = "deleteContactsfun";
export const GET_DELETE_CONTACTS_LOADING = "deleteContactsfunloading";

export const ERROR_GET_ALL_CONTACTS = "errorgetAllContactsfun";
export const ERROR_GET_SINGLE_CONTACTS = "errorgetSingleContactsfun";
export const ERROR_GET_POST_CONTACTS = "errorpostContactsfun";
export const ERROR_GET_PUT_CONTACTS = "errorputContactsfun";
export const ERROR_GET_DELETE_CONTACTS = "errordeleteContactsfun";
