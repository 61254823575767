import React from "react";
import Logins from "../../components/Login/Logins";

const Login = () => {
  return (
    <>
      <Logins />
    </>
  );
};

export default Login;
